
import Btn from "@/components/ui/Btn.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import RadioGroup from "@/components/ui/RadioGroup.vue";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, loadingMixin, nodeMixin } from "@/mixins";
import {
	CourseTreeNode as ICourseTreeNode,
	FileNode,
	PollNode,
	PollNodeChoice,
	PollNodeState,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import CourseTreeNode from "../node/CourseTreeNode.vue";
import { nodeEmits, nodeProps } from "../shared";
import { getColorFromString, md5, setErrorNotification } from "@/utils";

import { Pie } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { TChartData } from "vue-chartjs/dist/types";
import { DataFrequency, makeLabelText } from "@/reports/misc";
import { SelectableOption } from "@/interfaces";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

ChartJS.register(ArcElement, Tooltip, Legend);
export default defineComponent({
	name: "PollNodeDetail",
	mixins: [loadingMixin, nodeMixin, courseIdMixin],
	props: {
		node: {
			type: Object as PropType<PollNode>,
			required: true,
		},
		children: {
			type: Array as PropType<ICourseTreeNode[]>,
			default: () => [],
		},
		...nodeProps,
	},
	emits: {
		...nodeEmits,
	},
	created() {
		// TODO possibly extract shared logic
		this.$emit("loadChildren", { node: this.node, fromFirstPage: true });
		this.$emit("loadComments", this.node);
	},
	data() {
		return {
			PollNodeState,
			draftSelectedChoiceId: null as null | string,
		};
	},
	methods: {
		onEdit() {
			this.$emit("editNode", this.node);
		},
		async onVote(choiceId: string) {
			await this.withLoading(async () => {
				await this.mainStore.votePollNodeChoice({
					courseId: this.courseId,
					nodeId: this.node.id,
					choiceId,
					remove: false,
				});
			}, setErrorNotification);
		},
	},
	computed: {
		...mapStores(useMainStore),
		chartOptions() {
			return { responsive: false, maintainAspectRatio: false };
		},
		pollChoicesSelectionFrequency(): DataFrequency<PollNodeChoice>[] {
			return this.node.choices
				.map(c => ({ datum: c, frequency: c.votes ?? 0 }))
				.sort((a, b) => (String(a.datum.id) < String(b.datum.id) ? -1 : 1));
		},
		// TODO extract shared logic
		pollVotesData(): TChartData<"pie", number[], unknown> {
			const colors = this.node.choices.reduce((acc, e) => {
				const text = md5(e.text);
				const color = getColorFromString(text);
				acc[e.text] = color + "B3";
				return acc;
			}, {} as Record<string, string>);
			return {
				labels: this.pollChoicesSelectionFrequency.map(
					r =>
						makeLabelText(r.datum.text).slice(0, 100) +
						(makeLabelText(r.datum.text).length <= 100 ? "" : "..."),
				),
				datasets: [
					{
						data: this.pollChoicesSelectionFrequency.map(r => r.frequency),
						backgroundColor: this.pollChoicesSelectionFrequency.map(
							r => colors[r.datum.text],
						),
					},
				],
			};
		},
		selectedChoice() {
			// ! assumess only one choice can be selected
			return this.node.choices.filter(c => c.selected)[0];
		},
		textPreview() {
			return this.node.text;
		},
		canVote() {
			return this.node.state === PollNodeState.OPEN && !this.selectedChoice;
		},
		showResults() {
			return !this.canVote;
		},
		pollChoicesAsOptions(): SelectableOption[] {
			return this.node.choices.map(o => ({
				content: o.text,
				value: o.id,
			}));
		},
	},
	components: {
		Timestamp,
		ProcessedTextFragment,
		Btn,
		SlotSkeleton,
		//CourseTreeNode,
		CopyToClipboard,
		RadioGroup,
		Pie,
	},
});
